<template>
  <div class="roleAdmin">
    <div class="facilityBox" style="max-height:84.7vh">
      <div class="topbtns">
        <el-button type="success" size="medium" @click="addRole('add')" icon="el-icon-circle-plus-outline">添加</el-button>
        <el-button type="danger" size="medium" @click="deleteRole" icon="el-icon-remove-outline">删除</el-button>
      </div>
      <el-form class="searchForm" :model="searchForm" ref="searchForm" label-width="90px">
        <el-form-item label="角色名" prop="EnterpriseFullName" label-width="70px">
          <el-input v-model="searchForm.name" placeholder="请输入角色名"></el-input>
        </el-form-item>

        <el-form-item label="日期查询" prop="field3">
          <el-date-picker v-model="searchForm.dateTime" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="medium" @click="search('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" icon="el-icon-delete" @click="resetForm">清空</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        row-key="ID"
        :header-cell-style="{background:'#f0f0f0',color:'#666'}"
        @selection-change="selectRole"
         v-loading="loading"
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          align="center"
          width="50"
        >
        </el-table-column>
        <el-table-column align="center" prop="sindex" label="序号" width="50">
        </el-table-column>

        <el-table-column align="center" prop="Name" label="角色名称">
        </el-table-column>
        <el-table-column align="center" prop="Status" label="角色使用状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.Status === '使用' ? 'success' : 'danger'">{{scope.row.Status}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Remark" label="角色描述">
        </el-table-column>
        <el-table-column align="center" prop="PeopleNums" label="赋予人数">
        </el-table-column>

        <el-table-column align="center" prop="CreateDatetime" label="创建时间">
        </el-table-column>

        <el-table-column align="center" label="操作" width="220">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="giveRole(scope.row)" icon="el-icon-key"
              >分配权限</el-button
            >
            <el-button
              type="primary"
              size="small" icon="el-icon-edit-outline"
              @click="addRole('edit', scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div style="text-align: left; margin-top: 14px">
        <el-pagination class="pagination" @current-change="handleCurrentChange" :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next" :total="pagination.total">
        </el-pagination>
      </div>
    </div>
    <!--添加编辑角色-->
    <el-dialog top="25vh" :before-close="handleClose"
      class="roleDialog" :visible.sync="showPersonInfo" width="500px" :close-on-click-modal="false">
      <span slot="title">{{type == 'add' ? '添加角色' : '编辑角色'}}</span>
      <el-form :model="roleInfo" label-width="90px" ref="roleInfo" :rules="addrules">
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="roleInfo.name" auto-complete="off" maxlength="10" clearable placeholder="请输入角色名称">
          </el-input>
        </el-form-item>
        <el-form-item label="使用状态" prop="state">
          <el-select v-model="roleInfo.state" placeholder="请选择使用状态" style="width:313px">
            <el-option v-for="item in stateOption" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input style="width: 314px" v-model="roleInfo.desc" auto-complete="off" maxlength="200" clearable placeholder="请输入角色描述" type="textarea">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" v-show="type == 'add'" @click="addTable('roleInfo')">添加</el-button>
        <el-button type="primary" v-show="type == 'edit'" @click="onSumbitEdit('roleInfo')">确定修改</el-button>
        <el-button @click="closeDialog('roleInfo')">取 消</el-button>
      </div>
    </el-dialog>
    <!--分配权限弹框-->
    <el-dialog width="600px" class="roleInfoDialog" :visible.sync="giveRoleShow" :close-on-click-modal="false">
      <span slot="title">分配权限</span>
      <el-row style="font-size:14px;padding-left:14px;margin-bottom:12px">
        <el-col :span="9"><strong>角色名称：</strong><span>{{roleData.Name}}</span></el-col>
        <el-col :span="14">
          <strong>使用状态：</strong>
          <el-tag v-if="roleData.Enabled === 0" size="small" type="danger">{{roleData.Status}}</el-tag>
          <el-tag v-else size="small" type="success">{{roleData.Status}}</el-tag>
        </el-col>
      </el-row>
      <el-row style="font-size:14px;padding-left:14px">
        <el-col :span="9"><strong>创建时间：</strong><span> {{roleData.CreateDatetime}}</span></el-col>
        <el-col :span="14"><strong>角色描述：</strong><span >{{roleData.Remark ? roleData.Remark : '暂无描述'}}</span></el-col>
      </el-row>
      <div class="cas">
        <div class="tree">
          <div class="title-box">
            <div class="title">角色拥有权限</div>
          </div>
          <el-tree class="inner"
           :data="authOptions"
           show-checkbox
           node-key="ID"
           default-expand-all
           :check-strictly="true"
           ref="tree"
           :default-checked-keys="casValue"
           @check="nodeCheck"
           :props="defaultProps">
          </el-tree>
        </div>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="onSure">确定</el-button>
        <el-button @click="giveRoleShow = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRoleList,
  addRole,
  editRole,
  DeleteRole,
  getUserMenus,
  giveRoleMenus,
  getSelectMenuId
} from "@/api/power";
import basicMixin from "@/mixins/basic";
export default {
  mixins: [basicMixin],
  data() {
    return {
      loading: false,
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      casValue: [], // 选择角色可用的菜单
      stateOption: [
        {
          value: 1,
          label: "启用",
        },
        {
          value: 0,
          label: "禁用",
        },
      ],
      roleInfo: {
        name: "",
        phone: "",
        state: "",
        desc: "",
      }, // 人员信息
      // 添加表单验证
      addrules: {
        name: [
          {
            required: true,
            max: 50,
            message: "请输入角色名称",
            trigger: "blur",
          },
        ],

        state: [
          { required: true, message: "请选择使用状态", trigger: "change" },
        ],
        desc: [{ message: "请填写角色描述", max: 500, trigger: "blur" }],
      },
      showPersonInfo: false, // 显示添加编辑角色弹窗
      giveRoleShow: false, // 赋予角色权限
      selectedTableItemList: [],
      type: "add", // add 为添加，edit为编辑
      editInfo: {
        ID: "",
      }, // 编辑的信息
      searchForm: {
        EnterpriseFullName: "", //企业名称
        dateTime: ["", ""], //时间范围
        name: "", //姓名
      },
      roleSelectionList: [], // 选择了的角色列表
      tableData: [], //表格数据
      roleData: {},
      defaultProps: {
        children: "children",
        value: "ID",
        label: "Name",
      },
      authOptions: [],
    };
  },
  methods: {
    // 获取角色列表
    getRoleList() {
      let params = {
        RoleName: this.searchForm.name,
        DateStart: this.searchForm.dateTime[0],
        DateEnd: this.searchForm.dateTime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page, 
        userId: 0,
        RoleId: 0,
      };
      this.loading = true
      getRoleList(params).then((res) => {
        this.tableData = res.data.DataList;
        let i = 1;
        this.tableData.forEach((element) => {

          if (element.Remark.length > 60) {
            element.msg = element.Remark.substring(0, 60) + '...'
          } else {
            element.msg = element.Remark
          }
          element.sindex =
            (this.pagination.page - 1) * this.pagination.pagesize + i++;
        });
        this.pagination.total = Number(res.data.TotalCount);
        this.loading = false
      }).catch(err => {
        if (err.code === 403) {
          this.$router.push('/power/nopermission')
        }
      });
    },
    // 添加角色
    addTable(roleInfo) {
      this.$refs[roleInfo].validate((valid) => {
        if (valid) {
          let params = {
            name: this.roleInfo.name,
            enabled: this.roleInfo.state,
            remark: this.roleInfo.desc
          };
          addRole(params).then((res) => {
            this.showPersonInfo = false;
            this.pagination.page = 1;
            this.$refs[roleInfo].resetFields();
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.getRoleList();
          });
        }
      });
    },
    // 修改人员
    onSumbitEdit(roleInfo) {
      this.$refs[roleInfo].validate((valid) => {
        if (valid) {
          let params = {
            roleId: this.editInfo.ID,
            name: this.roleInfo.name,
            enabled: this.roleInfo.state,
            remark: this.roleInfo.desc ? this.roleInfo.desc : '',
          };
          editRole(params).then((res) => {
            this.showPersonInfo = false;
            this.$refs[roleInfo].resetFields();
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.getRoleList();
          });
        }
      });
    },
    // 取消弹窗
    closeDialog(roleInfo) {
      this.showPersonInfo = false;
      this.$refs[roleInfo].resetFields();
    },
    // 关闭弹窗
    handleClose(done) {
      done();
      this.$refs['roleInfo'].resetFields();
    },
    // 赋予角色
    onSure() {
      let str = this.casValue.join(',')
      let params = {
        roleId: this.roleData.ID,
        powerIds: str,
      };
      giveRoleMenus(params).then((res) => {
        this.$message({
          message: "分配权限成功",
          type: "success",
        });
        this.giveRoleShow = false;
      });
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.getRoleList();
    },
    //搜索
    search(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.searchForm.dateTime === null) {
            this.searchForm.dateTime = ["", ""]
          }
          //搜索前初始化page
          this.pagination.page = 1;
          this.getRoleList();
        }
      });
    },
    //清空
    resetForm() {
      this.searchForm.EnterpriseFullName = "";
      this.searchForm.name = "";
      this.searchForm.dateTime = ["", ""];
      this.pagination.page = 1;
      this.getRoleList();
    },
    // 选择人员角色
    selectRole(val) {
      this.roleSelectionList = val;
    },
    //新增角色
    addRole(scope, row) {
      this.showPersonInfo = true;
      if (scope == "add") {
        this.title = "add";
        this.type = "add";
        this.roleInfo.name = "";
        this.roleInfo.state = "";
        this.roleInfo.desc = "";
      }
      if (scope == "edit") {
        this.type = "edit";
        this.editInfo = row;
        this.roleInfo.name = row.Name;
        this.roleInfo.state = row.Enabled;
        this.roleInfo.desc = row.Remark;
      }
    },
    // 赋予角色弹框
    giveRole(row) {
      this.giveRoleShow = true;
      this.roleData = row;
      this.showValue = []
      // 传id 获取该角色已经获取的权限
      let params = {
        roleId: row.ID,
      };
      getUserMenus(params).then((res) => {
        // 判断是否有选中
        if (res.PowerJson == null) {
          this.casValue = [];
          this.$refs.tree.setCheckedKeys(this.showValue);
        } else {
          let newArr = res.PowerJson.split(',')
          this.casValue = newArr.map(item => Number(item))
          this.$refs.tree.setCheckedKeys(this.showValue);
        }
      });
    },
    //批量删除
    deleteRole() {
      if (this.roleSelectionList.length === 0) {
        this.$message.error("先“单击”一个角色，再点击“删除”按钮，即可删除。");
        return;
      }
      let arr = []
      this.roleSelectionList.forEach(element => {
        arr.push(element.ID)
      });

      let param = arr.join(",");
      this.$confirm(
        "您将删除此角色，关于此角色的所有数据将清空，是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          DeleteRole(param).then((res) => {
            this.pagination.page = 1;
            this.searchForm.EnterpriseFullName = "";
            this.searchForm.name = "";
            this.searchForm.dateTime = ["", ""];
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getRoleList();
          });
        })
        .catch(() => { });
    },
    // 子菜单关联上级菜单
    nodeCheck(currentNode, treeStatus) {
      // console.log(treeStatus, currentNode, 'zzzz')
      const setChildStatus = (node, status) => {
        // console.log(node, status, 'ssss')
        /* 这里的 id children 也可以是其它字段，根据实际的业务更改 */
        this.$refs.tree.setChecked(node.ID, status);
        if (node.children) {
          /* 循环递归处理子节点 */
          for (let i = 0; i < node.children.length; i++) {
            setChildStatus(node.children[i], status);
          }
        }
      };
      /* 设置父节点为选中状态 */
      const setParentStatus = (nodeObj) => {
        /* 拿到tree组件中的node,使用该方法的原因是第一次传入的 node 没有 parent */
        const node = this.$refs.tree.getNode(nodeObj);
        if (node.parent.key) {
          this.$refs.tree.setChecked(node.parent, true);
          setParentStatus(node.parent);
        }
      };
      /* 判断当前点击是选中还是取消选中操作 */
      if (treeStatus.checkedKeys.includes(currentNode.ID)) {
        setParentStatus(currentNode);
        setChildStatus(currentNode, true);
      } else {
        /* 取消选中 */
        if (currentNode.children) {
          setChildStatus(currentNode, false);
        }
      }
      // 获取到所有选中的节点，拿到ID
      let roles = [...this.$refs.tree.getCheckedNodes()];
      // console.log(roles, 'sss')
      let arr = []
      roles.forEach((item) => {
        arr.push(item.ID)
      })
      let menuArr = this.unique(arr); // 去除重复的节点
      this.casValue = menuArr
    },
    // 数组去重
    unique(arr) {
      let newArr = []
      let len = arr.length
      for (let i = 0; i < len; i++) {
        if (newArr.indexOf(arr[i]) == -1) {
          newArr.push(arr[i])
        }
      }
      return newArr
    },
    getMenu() {
      let params = {
        roleId: 0,
      };
      // 获取所有菜单列表 传0
      getUserMenus(params).then((res) => {
        this.authOptions = res.data;
      });
    },
  },

  created() {
    this.getRoleList();
    this.getMenu();
  },
};
</script>
<style lang="scss">
.roleAdmin {
  .role {
    .el-dialog__body {
      display: flex;
      justify-content: center;
    }
  }

  .infos {
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 16px;
    width: 320px;
    display: flex;
    justify-content: center;
    .el-form-item {
      margin-bottom: 0;
    }
    .el-form-item__label {
      text-align: left;
      color: #333333;
    }
    .el-input__inner {
      border: none;
    }
  }
  .info {
    margin-bottom: 16px;
    width: 320px;
    font-size: 14px;
    padding-left: 6px;
    .el-form-item {
      margin-bottom: 0;
    }
    .el-textarea__inner {
      height: 80px;
    }
    .name {
      margin-bottom: 8px;
      margin-left: 10px;
    }
    .el-form-item__error {
      left: 14px;
    }
    .el-input__inner {
      border: none;
    }
  }
}
</style>
<style lang="scss" scoped>
.roleAdmin {
  ::v-deep .roleDialog {
    .el-dialog__body {
      display: flex;
      justify-content: center;
    }
    .el-dialog__header {
      border-bottom: 1px solid #eee;
    }
    .el-dialog__footer{
      border-top: 1px solid #eee;
      text-align: center;
    }
    .el-dialog__body {
      padding: 20px;
    }
    .infos {
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 16px;
      width: 420px;
      display: flex;
      justify-content: flex-start;
      .el-form-item {
        width: 420px;
        margin-bottom: 0;
      }
      .el-form-item__label {
        text-align: left;
        color: #333333;
      }
      .el-input__inner {
        width: 330px;
        border: none;
      }
    }
    .info {
      margin-bottom: 16px;
      width: 420px;
      font-size: 14px;
      padding-left: 6px;
      .el-form-item {
        margin-bottom: 0;
      }
      .el-textarea {
        width: 420px !important;
      }
      .el-textarea__inner {
        height: 80px;
      }
      .name {
        margin-bottom: 8px;
        margin-left: 10px;
      }
      .el-form-item__error {
        left: 14px;
      }
      .el-input__inner {
        border: none;
      }
    }
  }
  .roleInfoDialog{
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }
    ::v-deep .el-dialog__footer{
      border-top: 1px solid #eee;
      text-align: center;
    }
    ::v-deep .el-dialog__body {
      padding: 20px;
    }
  }
  .topbtns{
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }
  .btns {
    margin-bottom: 20px;
  }
  .roleList {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    overflow-x: auto;
    .item {
      background: #ffffff;
      box-shadow: 0px 1px 15px rgba(51, 51, 51, 0.1);
      display: inline-block;
      border-radius: 4px;
      padding: 0 8px;
      width: 350px;
      box-sizing: border-box;
      margin-bottom: 20px;
      margin-left: 10px;
      border: 1px solid #fff;
      .img {
        text-align: center;
        img {
          width: 80px;
          height: 80px;
          margin-top: 32px;
          margin-bottom: 8px;
        }
      }
      .name {
        text-align: center;
        color: #eee;
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 8px;
      }
      .isUse {
        text-align: center;
        margin-bottom: 4px;
        font-weight: bold;
        font-size: 20px;
      }
      .remark {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
        width: 330px;
        height: 56px;
      }
      .num {
        font-size: 16px;
        color: #333333;
        margin-bottom: 4px;
      }
      .company {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        color: #999999;
      }
      .time {
        color: #999999;
        margin-bottom: 8px;
        font-size: 14px;
      }
      .auth {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        font-size: 16px;
        .assign {
          display: flex;
          align-items: center;
          color: #fff;
          cursor: pointer;
          padding: 4px 8px;
          background: #2081ff;
          box-shadow: 0px 3px 6px rgba(32, 129, 255, 0.3);
          border-radius: 6px;
          img {
            width: 22px;
            height: 22px;
          }
        }
        .assign:last-child {
          padding: 4px 20px;
        }
      }
    }
    .checked {
      border: 1px solid #333 !important;
    }
  }
  .cas {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
    .tree {
      width: 100%;
      background: #ffffff;
      border-radius: 12px;
      display: inline-block;
      // box-shadow: -1px 4px 20px rgba(0, 0, 0, 0.13);
      border: 1px solid #eee;
      padding: 24px 0 32px 0;
      height: 400px;
      overflow-y: auto;
      .inner {
        margin-right: 170px;
        margin-left: 24px;
      }
      .title-box {
        color: #999999;
        margin-bottom: 16px;
        padding: 0 24px 8px;
        border-bottom: 1px solid #f5f5f5;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
      }
    }
    .tree::-webkit-scrollbar{
      width: 6px;
    }
    .tree::-webkit-scrollbar-thumb {
      background: #ddd;
    }
  }
  .dialog-btn {
    margin-left: 16%;
    .btn {
      background: #aaa;
      box-shadow: 0px 3px 6px rgba(32, 129, 255, 0.3);
      color: #fff;
      font-weight: 400;
      padding: 5px 24px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .roleInfo {
    display: flex;
    border-bottom: 1px solid #f5f5f5;
    justify-content: space-between;
    padding-bottom: 24px;
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  .searchForm {
    display: flex;
    flex-wrap: wrap;
  }
  .addForm {
    display: flex;
    flex-wrap: wrap;
    .el-item {
      width: 50%;
    }
    .el-date-editor {
      width: 100% !important;
    }
  }
  .pagination {
    margin-top: 10px;
  }
  .addDialog {
    .body {
      .el-autocomplete {
        width: 100%;
      }
      .choose {
        margin-top: 50px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>